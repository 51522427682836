import BrandLogo from '@/common/atoms/BrandLogo'
import React from 'react'
import { Outlet } from 'react-router-dom'

export const AuthLayout = () => {
  return (
    <div className="tw-grid tw-grid-flow-col tw-grid-cols-1 md:tw-grid-cols-2 tw-h-screen tw-overflow-hidden">
      <div className="tw-overflow-y-auto">
        <div className="tw-flex tw-items-center tw-justify-center tw-py-12 tw-min-h-full">
          <div className="tw-mx-auto tw-w-[350px] tw-space-y-36">
            <BrandLogo size="lg" />
            <Outlet />
          </div>
        </div>
      </div>
      <div className="tw-dark:bg-gray-800 md:tw-h-screen tw-p-6 tw-hidden md:tw-block">
        <img
          src="https://images.unsplash.com/photo-1520608421741-68228b76b6df?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="Cityscape"
          className="tw-h-full tw-w-full tw-object-cover tw-rounded-xl"
        />
      </div>
    </div>
  )
}
