import React, { useMemo } from 'react'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import { MapPinIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { EstatesCardFooter } from './EstatesCardFooter'
import { EstateWithApplicationsAndVisitSpans } from '@/estate/hooks/useEstateWithApplicationsAndVisitSpans'
import { EstateCardMediaPreview } from './EstateCardMediaPreview'
import { EstateStatusBadge } from '../EstateStatusBadge/EstateStatusBadge'
import { formatCurrency } from '@almaris/shared/utils/formatCurrency'
import { getEstateStatusBasedLink } from '@/estate/utils/getEstateStatus'
import { useNavigateSeller } from '@/seller/hooks/useNavigateSeller'

export type EstateCardProps = {
  estate: EstateWithApplicationsAndVisitSpans
}
export const EstateCard = ({ estate }: EstateCardProps) => {
  const { t } = useTranslation()
  const link = getEstateStatusBasedLink(estate)
  const navigate = useNavigateSeller()

  const prices = useMemo(() => {
    const firstPrice = estate.firstPrice

    return (
      <>
        {firstPrice ? (
          <p className="tw-text-base tw-font-medium">
            {formatCurrency(firstPrice)}
          </p>
        ) : (
          <p className="tw-text-base tw-font-medium">
            {t('EstatesCardList.noPrice')}
          </p>
        )}
      </>
    )
  }, [estate.firstPrice, t])

  const description = estate.type ? [t(`common.estate.${estate.type}`)] : []
  if (estate.features?.numberOfRooms)
    description.push(
      t('EstatesCardList.ofNumberOfRooms', {
        count: estate.features.numberOfRooms,
      })
    )

  return (
    <Card
      className="tw-group tw-cursor-pointer hover:tw-shadow-md"
      onClick={() => navigate(link)}
    >
      <CardHeader className="tw-justify-center tw-relative">
        <EstateCardMediaPreview estate={estate} />
        <div className="tw-absolute tw-top-1 tw-right-1">
          <EstateStatusBadge estate={estate} />
        </div>
      </CardHeader>

      <CardContent className="tw-grid tw-gap-4 tw-text-lg">
        <div>
          <CardTitle className="tw-text-lg">{estate.name}</CardTitle>
          {prices}
        </div>
        <div>
          <CardDescription className="tw-text-sm tw-text-zinc-900">
            {description.join(' ')}
          </CardDescription>
          <div className="tw-flex tw-items-center tw-space-y-0">
            <MapPinIcon size={13} />
            <p className="tw-text-xs tw-font-semibold tw-ml-1">{estate.city}</p>
          </div>
        </div>
      </CardContent>
      <EstatesCardFooter {...estate} />
    </Card>
  )
}
