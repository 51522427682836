import { Loading } from '@/common/atoms/Loading'
import TextError from '@/common/atoms/TextError'
import { EstateNotFoundError } from '@/estate/utils/EstateNotFoundError'
import { useGetEstateForMandatePageQuery, useGetMandateQuery } from '@gql'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { MandateInfoForm } from '../components/MandateInfoForm'
import { MandateStatusCard } from '../components/MandateStatusCard'

export const MandatePage = () => {
  const { t } = useTranslation()
  const { estateId } = useParams<{ estateId: string }>()

  const {
    loading: estateLoading,
    error: estateError,
    data: estateData,
  } = useGetEstateForMandatePageQuery({
    variables: {
      id: estateId!,
    },
    skip: estateId == null,
    fetchPolicy: 'cache-and-network',
  })
  const estate = useMemo(() => estateData?.estate_by_pk, [estateData])

  // TODO: why estate.mandate is not directly used instead?
  const {
    loading: mandateLoading,
    error: mandateError,
    data: mandateData,
  } = useGetMandateQuery({
    variables: {
      id: estate?.mandate?.id!,
    },
    skip: estate?.mandate?.id == null,
    fetchPolicy: 'cache-and-network',
  })

  const mandate = mandateData?.mandate_by_pk

  return (
    <div className="tw-py-6 tw-px-8">
      <h1 className="tw-text-[40px] tw-font-semibold">
        {t('MandatePage.heading')}
      </h1>
      <div className="tw-w-full tw-grid md:tw-grid-cols-[1fr_40%] tw-gap-6 tw-py-4 md:tw-py-6">
        {(estateData == null && estateLoading) ||
        (mandateData == null && mandateLoading) ? (
          <Loading active />
        ) : estateError ? (
          <TextError error={estateError} />
        ) : mandateError ? (
          <TextError error={mandateError} />
        ) : estate == null ? (
          <TextError error={new EstateNotFoundError(t)} />
        ) : (
          <>
            <MandateInfoForm mandate={mandate} estate={estate} />
            <MandateStatusCard mandate={mandate} estate={estate} />
          </>
        )}
      </div>
    </div>
  )
}
