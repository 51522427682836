import { Separator } from '@/components/ui/separator'
import { useToast } from '@/components/ui/use-toast'
import { EstateForMainPageFragment, useUpdateEstateMutation } from '@gql'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { EstateConditionAndServicesForm } from './EstateConditionAndServicesForm'
import { EstateCoownershipForm } from './EstateCoownershipForm'
import { EstateFeaturesForm } from './EstateFeaturesForm'
import { EstateIdentityAndAddressForm } from './EstateIdentityAndAddressForm'
import { EstateEditFormCard } from './EstateEditFormCard'
import { FormProvider, useForm } from 'react-hook-form'
import { EstatePublicationForm } from './EstatePublicationForm'
import { yupResolver } from '@hookform/resolvers/yup'
import { EstateUpdateSchema } from '@/estate/hooks/useUpdateEstateSchema'
import { useUpdateEstateSchema } from '@/estate/hooks/useUpdateEstateSchema'
import { Orientation_Enum } from '@/common/atoms/OrientationMultiSelect'
import { Primary_Purpose_Enum } from '@/common/atoms/PrimaryPurposeSelect'
import { Equipment_Enum } from '@/common/atoms/EquipmentMultiSelect'
import { Occupation_Type_Enum } from '@/common/atoms/OccupationTypeSelect'
import AutoSave from '@/common/atoms/Autosave'

export type EstateEditFormProps = {
  estate: Pick<
    EstateForMainPageFragment,
    | 'id'
    | 'type'
    | 'name'
    | 'address'
    | 'zipCode'
    | 'city'
    | 'publicationTitle'
    | 'publicationDescription'
    | 'publicationMention'
    | 'canConnectedBuyerSeeAddress'
    | 'features'
    | 'servicesAndConditions'
    | 'coownership'
    | 'mandate'
    | 'canConnectedBuyerSeeAddress'
  >
}
export const EstateEditForm = ({ estate }: EstateEditFormProps) => {
  const { t } = useTranslation()
  const { toast } = useToast()

  const isMandateGenerated = useMemo(() => {
    return estate.mandate?.mandate_registry != null
  }, [estate.mandate?.mandate_registry])

  const [
    updateEstate,
    {
      data: updateEstateData,
      // loading: updateEstateLoading,
      error: updateEstateError,
    },
  ] = useUpdateEstateMutation({
    refetchQueries: ['getEstates'],
  })

  // Success toast
  useEffect(() => {
    if (updateEstateData) {
      toast({
        variant: 'success',
        description: t('EstateDiagnosticsTab.toast.success'),
      })
    }
  }, [updateEstateData, t, toast])

  // Error toast
  useEffect(() => {
    if (updateEstateError) {
      toast({
        variant: 'destructive',
        description: t('EstateDiagnosticsTab.toast.error'),
      })
    }
  }, [updateEstateError, t, toast])

  const { schema } = useUpdateEstateSchema()

  const form = useForm<EstateUpdateSchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: estate.name,
      address: estate.address,
      zipCode: estate.zipCode,
      city: estate.city,
      type: estate.type,
      publicationTitle: estate.publicationTitle,
      publicationDescription: estate.publicationDescription,
      publicationMention: estate.publicationMention,
      canConnectedBuyerSeeAddress: estate.canConnectedBuyerSeeAddress,
      features: {
        ...estate.features,
        primaryPurpose: estate.features?.primaryPurpose as Primary_Purpose_Enum,
        orientation: estate.features?.orientation as Orientation_Enum[],
        equipments: estate.features?.equipments as Equipment_Enum[],
        occupationType: estate.features?.occupationType as Occupation_Type_Enum,
      },
      servicesAndConditions: estate.servicesAndConditions,
      coownership: {
        ...estate.coownership,
        isCoowned: estate.coownership?.isCoowned ?? false,
      },
    },
    mode: 'onChange',
  })

  const onSubmit = form.handleSubmit(async (values) => {
    await updateEstate({
      variables: {
        id: estate.id,
        values,
      },
    })
  })

  return (
    <FormProvider {...form}>
      <AutoSave delay={1500} onSubmit={onSubmit} />
      <div className="tw-space-y-4">
        <EstateEditFormCard
          title={t('EstatePage.identityAndAddress')}
          defaultOpen={true}
        >
          <EstateIdentityAndAddressForm
            isMandateGenerated={isMandateGenerated}
          />
          {isMandateGenerated && (
            <p className="tw-text-sm tw-text-gray-500 tw-mt-2">
              {t('EstateIdentityAndAddressForm.editable')}
            </p>
          )}
        </EstateEditFormCard>

        <Separator className="tw-my-6" />

        <EstateEditFormCard
          title={t('EstatePage.features')}
          defaultOpen={false}
        >
          <EstateFeaturesForm estateType={estate.type ?? null} />
        </EstateEditFormCard>
        <Separator className="tw-my-6" />

        <EstateEditFormCard
          title={t('EstatePage.publication')}
          defaultOpen={false}
        >
          <EstatePublicationForm />
        </EstateEditFormCard>
        <Separator className="tw-my-6" />

        <EstateEditFormCard
          title={t('EstatePage.conditionAndServices')}
          defaultOpen={false}
        >
          <EstateConditionAndServicesForm estateType={estate.type ?? null} />
        </EstateEditFormCard>
        <Separator className="tw-my-6" />

        <EstateEditFormCard
          title={t('EstatePage.coownership')}
          defaultOpen={false}
        >
          <EstateCoownershipForm />
        </EstateEditFormCard>
        <Separator className="tw-my-6" />
      </div>
    </FormProvider>
  )
}
