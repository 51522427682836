import { NhostReactClientConstructorParams } from '@nhost/react'

export const isLocal = ['localhost', '127.0.0.1'].includes(location.hostname)
const isStaging = location.hostname === 'app-stg.almaris.fr'
const isDemo = location.hostname === 'app-demo.almaris.fr'

const environment = isLocal
  ? 'development'
  : isStaging
    ? 'staging'
    : isDemo
      ? 'demo'
      : 'production'

// TODO use env variables, find a way to use env var with nhost+turbo+vitejs
const localConfig = {
  url: 'http://localhost:3000',
  backendUrl: 'http://localhost:8888',
  websiteUrl: 'http://localhost:3001',
  nhost: {
    subdomain: 'local',
    region: undefined,
  } as NhostReactClientConstructorParams,
}

const stagingConfig = {
  url: 'https://app-stg.almaris.fr',
  backendUrl: 'https://api-stg.almaris.fr',
  websiteUrl: 'https://stg.almaris.fr',
  nhost: {
    subdomain: 'nwjzgkyomllmfiwfpvdn',
    region: 'eu-central-1',
  } as NhostReactClientConstructorParams,
}

const demoConfig = {
  url: 'https://app-demo.almaris.fr',
  backendUrl: 'https://api-demo.almaris.fr',
  websiteUrl: 'https://demo.almaris.fr',
  nhost: {
    subdomain: 'hccdrnhgosysdcfborng',
    region: 'eu-central-1',
  } as NhostReactClientConstructorParams,
}

const productionConfig = {
  url: 'https://app.almaris.fr',
  backendUrl: 'https://api.almaris.fr',
  websiteUrl: 'https://estate.almaris.fr',
  nhost: {
    subdomain: 'frcfbmufkgjicngpqntx',
    region: 'eu-central-1',
  } as NhostReactClientConstructorParams,
}

const config = {
  ...(isLocal
    ? localConfig
    : isStaging
      ? stagingConfig
      : isDemo
        ? demoConfig
        : productionConfig),

  // Files
  memberPicture: {
    maxSize: 300, // in px
  },

  sentry: {
    enabled: environment !== 'development',
    dsn: 'https://c6253058614d1a0948be4f6ee3bec677@o4508252321021952.ingest.de.sentry.io/4508291900571728',
    environment,
  },

  radarIo: {
    apiKey: import.meta.env.VITE_RADAR_IO_API_KEY ?? '',
  },
}

export default config
