import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Switch } from '@/components/ui/switch'
import { InputNumber } from '@/components/ui/inputNumber'
import { EstateUpdateSchema } from '@/estate/hooks/useUpdateEstateSchema'

export const EstateCoownershipForm = () => {
  const { t } = useTranslation()
  const { control, watch } = useFormContext<EstateUpdateSchema>()

  const isCoowned = watch('coownership.isCoowned')

  return (
    <div className="tw-grid tw-grid-cols-6 tw-gap-4 tw-items-baseline">
      <FormField
        control={control}
        name="coownership.isCoowned"
        render={({ field }) => (
          <FormItem className="tw-col-span-6 tw-flex tw-flex-row tw-gap-4 tw-items-center">
            <FormLabel>{t('EstateCoownershipForm.isCoowned')}</FormLabel>
            <FormControl>
              <Switch checked={field.value} onCheckedChange={field.onChange} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      {isCoowned && (
        <>
          <FormField
            control={control}
            name="coownership.syndicExtranetURL"
            render={({ field }) => (
              <FormItem className="tw-col-span-6">
                <FormLabel>
                  {t('EstateCoownershipForm.syndicExtranetURL')}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    value={field.value === null ? '' : field.value}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name="coownership.syndicExtranetLogin"
            render={({ field }) => (
              <FormItem className="tw-col-span-3">
                <FormLabel>
                  {t('EstateCoownershipForm.syndicExtranetLogin')}
                </FormLabel>
                <FormControl>
                  <Input
                    autoComplete="off"
                    {...field}
                    value={field.value === null ? '' : field.value}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name="coownership.syndicExtranetPassword"
            render={({ field }) => (
              <FormItem className="tw-col-span-3">
                <FormLabel>
                  {t('EstateCoownershipForm.syndicExtranetPassword')}
                </FormLabel>
                <FormControl>
                  <Input
                    autoComplete="off"
                    {...field}
                    value={field.value === null ? '' : field.value}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name="coownership.totalLotsNumber"
            render={({ field }) => (
              <FormItem className="tw-col-span-3">
                <FormLabel>
                  {t('EstateCoownershipForm.totalLotsNumber')}
                </FormLabel>
                <FormControl>
                  <InputNumber
                    {...field}
                    className="tw-w-full"
                    min={0}
                    value={field.value === null ? undefined : field.value}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name="coownership.ongoingProcedures"
            render={({ field }) => (
              <FormItem className="tw-col-span-3 tw-flex tw-flex-col tw-gap-4 tw-items-start">
                <FormLabel>
                  {t('EstateCoownershipForm.ongoingProcedures')}
                </FormLabel>
                <FormControl>
                  <Switch
                    checked={field.value ?? undefined}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </>
      )}
    </div>
  )
}
