import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { WebappApplicationFragment } from '@gql'
import { ColumnDef } from '@tanstack/react-table'
import { formatDate, TABLE_DATE_FORMAT } from '@almaris/shared/utils/dates'
import { formatCurrency } from '@almaris/shared/utils/formatCurrency'
import { ChevronsUpDownIcon } from 'lucide-react'
import React from 'react'
import { TFunction } from 'react-i18next'
import { getLatestVisitSlotDate } from '../../../../utils/getLatestVisitSlotDate'
import { ApplicationsStarScoreRow } from '../ApplicationsStarScoreRow'
import { ApplicationStatusBadge } from '../ApplicationStatusBadge/ApplicationStatusBadge'

export const columns = (
  t: TFunction,
  openApplication: (applicationIndex: number) => void
): ColumnDef<WebappApplicationFragment, any>[] => [
  {
    accessorKey: 'name',
    filterFn: (row, _columnId, filterValue: string) => {
      return (
        row.original.buyer_member.user?.displayName != null &&
        row.original.buyer_member.user?.displayName
          .toLowerCase()
          .includes(filterValue.toLowerCase())
      )
    },
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          size="sm"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t('ApplicationsTableColumns.name')}
          <ChevronsUpDownIcon className="tw-ml-2 tw-h-4 tw-w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const application = row.original
      return (
        <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
          <div
            className="tw-flex tw-flex-col tw-items-start tw-cursor-pointer"
            onClick={() => openApplication(row.index)}
          >
            <p className="tw-text-sm tw-font-medium tw-text-zinc-900">
              {application.buyer_member.user?.displayName}
            </p>
            <p className="tw-text-sm tw-font-medium tw-text-zinc-500">
              {application.buyer_member.user?.email ??
                t('ApplicationsTableColumns.noData')}
            </p>
          </div>
        </div>
      )
    },
  },

  {
    accessorKey: 'status',
    filterFn: (row, _columnId, filterValue: string[]) => {
      const status = row.original.status
      return filterValue.includes(status)
    },
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          size="sm"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t('ApplicationsTableColumns.status')}
          <ChevronsUpDownIcon className="tw-ml-2 tw-h-4 tw-w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      // TODO compute status from visit_slots and application.status
      return <ApplicationStatusBadge application={row.original} />
    },
  },
  {
    accessorKey: 'visitDate',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          size="sm"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t('ApplicationsTableColumns.visitDate')}
          <ChevronsUpDownIcon className="tw-ml-2 tw-h-4 tw-w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const latestVisitSlotDate = getLatestVisitSlotDate(
        row.original.buyer_member.visit_slots
      )
      if (latestVisitSlotDate == null) return null

      const day = formatDate(latestVisitSlotDate, { format: TABLE_DATE_FORMAT })
      const hour = formatDate(latestVisitSlotDate, { format: 'p' })
      return (
        <div>
          <p>{day}</p>
          <p className="tw-text-zinc-500">{hour}</p>
        </div>
      )
    },
    sortingFn: (rowA, rowB, columnId) => {
      const latestVisitSlotDateA = getLatestVisitSlotDate(
        rowA.original.buyer_member.visit_slots
      )
      const latestVisitSlotDateB = getLatestVisitSlotDate(
        rowB.original.buyer_member.visit_slots
      )
      if (latestVisitSlotDateA == null || latestVisitSlotDateB == null) {
        return 0
      }
      return latestVisitSlotDateA.getTime() - latestVisitSlotDateB.getTime()
    },
  },
  {
    accessorKey: 'submitDate',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          size="sm"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t('ApplicationsTableColumns.offerDate')}
          <ChevronsUpDownIcon className="tw-ml-2 tw-h-4 tw-w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      {
        /* TODO add a submittedDate in DB for Application and use it here */
      }

      const application = row.original

      if (!application.submitDate)
        return (
          <p className="tw-text-zinc-500">
            {t('ApplicationsTableColumns.noData')}
          </p>
        )

      const day = formatDate(application.submitDate, {
        format: TABLE_DATE_FORMAT,
      })
      const hour = formatDate(application.submitDate, { format: 'p' })

      return (
        <div>
          <p>{day}</p>
          <p className="tw-text-zinc-500">{hour}</p>
        </div>
      )
    },
  },
  {
    accessorKey: 'offerAmount',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          size="sm"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t('ApplicationsTableColumns.offerAmount.label')}
          <ChevronsUpDownIcon className="tw-ml-2 tw-h-4 tw-w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const application = row.original
      return application.offerAmount ? (
        <p>{formatCurrency(application.offerAmount)}</p>
      ) : (
        <p className="tw-text-zinc-500">
          {t('ApplicationsTableColumns.noData')}
        </p>
      )
    },
  },
  {
    accessorKey: 'contribution',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          size="sm"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t('ApplicationsTableColumns.contribution.label')}
          <ChevronsUpDownIcon className="tw-ml-2 tw-h-4 tw-w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const application = row.original

      const contributionPercent = (
        ((application?.contribution ?? 0) / (application?.offerAmount ?? 0)) *
        100
      ).toFixed(2)

      return application.contribution ? (
        <p>
          {t('ApplicationsTableColumns.contribution.amount', {
            percent: contributionPercent,
          })}
        </p>
      ) : (
        <p className="tw-text-zinc-500">0 €</p>
      )
    },
    enableGrouping: false,
  },

  {
    accessorKey: 'score',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          size="sm"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          {t('ApplicationsTableColumns.score')}
          <ChevronsUpDownIcon className="tw-ml-2 tw-h-4 tw-w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const application = row.original
      return <ApplicationsStarScoreRow application={application} />
    },
  },

  {
    accessorKey: 'tags',
    header: () => {
      return <p>{t('ApplicationsTableColumns.tags')}</p>
    },
    cell: ({ row }) => {
      const application = row.original
      const tags = application.tags
      if (!tags) return null
      return (
        <div className="tw-flex tw-flex-row tw-gap-2 tw-flex-wrap tw-max-w-[200px]">
          {tags.map((tag: string) => (
            <Badge key={tag} variant="secondary">
              {tag}
            </Badge>
          ))}
        </div>
      )
    },
  },
]
