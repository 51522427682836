import ApplicationEntry from '@/application/components/sheet/ApplicationEntry'
import { Badge } from '@/components/ui/badge'
import {
  BuyerMemberFolderFragment,
  useGetBuyerMemberFoldersByApplicationIdQuery,
  WebappApplicationFragment,
} from '@gql'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ApplicationMediasPreview } from './ApplicationMediasPreview'
import { ApolloError } from '@apollo/client'
import { Loading } from '@/common/atoms/Loading'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { BuyerMemberFolders } from '../BuyerMemberFolders'
import { getLatestVisitSlotDate } from '@utils/getLatestVisitSlotDate'
import { formatCurrency } from '@almaris/shared/utils/formatCurrency'
import { ApplicationStatusBadge } from '../ApplicationStatusBadge/ApplicationStatusBadge'
import { useNetSeller } from '../../hooks/useNetSeller'

type AdminContentProps = {
  application: WebappApplicationFragment
  netSeller: number
}

const AdminContent = ({ application, netSeller }: AdminContentProps) => {
  const { t } = useTranslation()

  const { data, loading } = useGetBuyerMemberFoldersByApplicationIdQuery({
    variables: { applicationId: application.id },
    onError: (e: ApolloError) => console.error(e.message),
  })

  const buyerMemberFolders = useMemo(
    () => data?.buyer_member_folder ?? [],
    [data]
  )

  const phone = useMemo(() => {
    const getBmfPhone = (bmf: BuyerMemberFolderFragment) =>
      bmf.mobilePhone ?? bmf.homePhone ?? bmf.workPhone
    const bmfWithPhone = buyerMemberFolders.find(getBmfPhone)
    if (!bmfWithPhone) return application.buyer_member.user?.phoneNumber ?? ''
    return getBmfPhone(bmfWithPhone)
  }, [buyerMemberFolders, application])

  const isArchived = useMemo(() => application.archived, [application])

  const visitDate = useMemo(() => {
    const latestVisitSlotDate = getLatestVisitSlotDate(
      application.buyer_member.visit_slots
    )
    if (latestVisitSlotDate == null) return t('ApplicationSheet.notDefined')

    return format(latestVisitSlotDate, 'dd MMMM yyyy HH:mm', { locale: fr })
  }, [application.buyer_member.visit_slots, t])

  const contributionPercent = useMemo(() => {
    if (!application) return 0
    const { offerAmount, contribution } = application
    if (!offerAmount || !contribution) return 0
    return (contribution / offerAmount) * 100
  }, [application])

  //TODO: in the future only show the first document and add a button to see the others ?
  const documents = useMemo(() => {
    if (!application.application_medias) return t('ApplicationSheet.notDefined')

    return (
      <div className="tw-flex tw-flex-wrap tw-gap-2">
        {application.application_medias?.map((applicationMedia) => (
          <ApplicationMediasPreview
            key={applicationMedia.id}
            applicationMedia={applicationMedia}
          />
        ))}
      </div>
    )
  }, [application.application_medias, t])

  if (loading) return <Loading center active />
  if (!application) return null
  return (
    <Tabs defaultValue="application" className="tw-w-full">
      <TabsList className="tw-h-auto tw-max-h-[100px] tw-flex-wrap tw-overflow-auto tw-gap-2 tw-justify-stretch">
        <TabsTrigger value="application">
          {t('ApplicationSheet.application')}
        </TabsTrigger>
        <TabsTrigger value="buyerMemberFolders">
          {t('ApplicationSheet.buyerMemberFolders')}
        </TabsTrigger>
      </TabsList>
      <TabsContent value="application">
        <div className="tw-flex tw-flex-col tw-gap-4">
          {isArchived && (
            <Badge variant="destructive" className="tw-mb-2 tw-w-fit">
              {t('ApplicationsTableColumns.archived')}
            </Badge>
          )}
          <ApplicationEntry
            title={t('ApplicationSheet.candidate')}
            value={
              application.buyer_member.user?.displayName ??
              application.buyer_member.user?.email!
            }
          />
          <ApplicationEntry
            title={t('ApplicationsTableColumns.status')}
            value={<ApplicationStatusBadge application={application} />}
          />
          <ApplicationEntry
            title={t('ApplicationsTableColumns.visitDate')}
            value={visitDate}
          />
          {/* TODO add a submittedDate in DB for Application and use it here */}
          <ApplicationEntry
            title={t('ApplicationSheet.offerDate')}
            value={
              application.submitDate
                ? format(
                    new Date(application.submitDate),
                    'dd MMMM yyyy HH:mm',
                    {
                      locale: fr,
                    }
                  )
                : t('ApplicationSheet.notDefined')
            }
          />
          <ApplicationEntry
            title={t('ApplicationSheet.contribution')}
            value={contributionPercent.toFixed(2) + '%'}
          />
          <ApplicationEntry
            title={t('ApplicationSheet.loanAmount')}
            value={formatCurrency(application.loan ?? 0)}
          />
          {/* <ApplicationEntry
            title={t('ApplicationSheet.cotePartAcqui')}
            value={cotePartAcqui.toFixed(2) + '%'}
          /> */}
          <ApplicationEntry
            title={t('ApplicationSheet.totAmount')}
            value={formatCurrency(application.offerAmount ?? 0)}
          />
          <ApplicationEntry
            title={t('ApplicationSheet.netSeller')}
            value={formatCurrency(netSeller)}
          />
          {/* Email acquéreur  */}
          <ApplicationEntry
            title={t('ApplicationSheet.email')}
            value={application.buyer_member.user?.email ?? ''}
          />
          {/* Téléphone acquéreur  */}
          <ApplicationEntry title={t('ApplicationSheet.phone')} value={phone} />

          {/* Information du notaire  */}
          <ApplicationEntry
            title={t('ApplicationSheet.notary')}
            value={[
              application.notary.firstName,
              application.notary.lastName,
              application.notary.city,
              application.notary.email,
              application.notary.phone,
            ].join(' ')}
          />
          <ApplicationEntry
            title={t('ApplicationSheet.documents')}
            value={documents}
          />
          <ApplicationEntry
            title={t('ApplicationSheet.comment')}
            value={application.comment ?? t('ApplicationSheet.notDefined')}
          />
        </div>
      </TabsContent>
      <TabsContent value="buyerMemberFolders">
        <BuyerMemberFolders buyerMemberFolders={buyerMemberFolders} />
      </TabsContent>
    </Tabs>
  )
}

export default AdminContent
