import { Skeleton } from '@/components/ui/skeleton'
import { useGetPresignedUrl } from '@/file/hooks/useGetPresignedUrl'
import { Estate_Media_Type_Group_Enum, WebappSimpleEstateFragment } from '@gql'
import React from 'react'
import HouseSvg from 'src/images/house.svg'
import { useEstateMedias } from '@/estateMedia/hooks/useEstateMedias'

type EstateCardMediaPreviewProps = {
  estate: WebappSimpleEstateFragment
}

export const EstateCardMediaPreview = ({
  estate,
}: EstateCardMediaPreviewProps) => {
  const { estateMedias, loading: useEstateMediasLoading } = useEstateMedias({
    estateId: estate.id,
    typeGroup: Estate_Media_Type_Group_Enum.Media,
  })

  const fileId = estateMedias[0]?.fileId

  const { url, loading: useGetPresignedUrlLoading } = useGetPresignedUrl({
    fileId: fileId!,
    skip: !fileId,
  })

  if (useEstateMediasLoading || useGetPresignedUrlLoading) {
    return (
      <div className="tw-w-[304px] tw-h-[270px] tw-overflow-hidden">
        <Skeleton className="tw-h-10 tw-w-10" />
      </div>
    )
  }

  return (
    <div className="tw-w-[304px] tw-h-[270px] tw-overflow-hidden">
      {url ? (
        <img
          src={url ?? ''}
          alt="Estate picture"
          className="tw-object-cover tw-w-full tw-h-full"
        />
      ) : (
        <div className="tw-relative tw-bg-zinc-50 tw-rounded-lg tw-flex tw-items-center tw-justify-center tw-size-full">
          <HouseSvg />
        </div>
      )}
    </div>
  )
}
