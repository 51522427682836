import { useMemo } from 'react'

export interface UseNetSellerParams {
  offerAmount: number | null
  fixedFee: number
  proportionalFeesRate: number
  feeVATRate: number
  fixedFeeThreshold: number
}

export const useNetSeller = ({
  offerAmount,
  fixedFee,
  proportionalFeesRate,
  feeVATRate,
  fixedFeeThreshold,
}: UseNetSellerParams) => {
  return useMemo(() => {
    const amount = offerAmount ?? 0

    const fees =
      amount <= fixedFeeThreshold
        ? fixedFee
        : (proportionalFeesRate / 100) * amount

    const feesPlusTaxes = fees * (1 + feeVATRate / 100)

    return amount - feesPlusTaxes
  }, [
    offerAmount,
    fixedFee,
    proportionalFeesRate,
    feeVATRate,
    fixedFeeThreshold,
  ])
}
