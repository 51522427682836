import useIsAgencyMember from '@/agencyMember/hooks/useIsAgencyMember'
import { Card, CardContent } from '@/components/ui/card'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { InputDatePicker } from '@/components/ui/InputDatePicker'
import { InputPrice } from '@/components/ui/inputPrice'
import { Label } from '@/components/ui/label'
import { Textarea } from '@/components/ui/textarea'
import {
  EstateForMandatePageFragment,
  GetMandateDocument,
  GetEstateForMandatePageDocument,
  Mandate_Fee_Person_Enum,
  MandateFragment,
  useCreateMandateMutation,
  useUpdateMandateMutation,
} from '@gql'
import { yupResolver } from '@hookform/resolvers/yup'
import { omit } from '@utils/omit'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import React, { useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  MandateInfoFormValues,
  useMandateInfoSchema,
} from '../hooks/useMandateInfoSchema'
import { MandateFeePersonSelect } from './MandateFeePersonSelect'
import { MandateTypeSelect } from './MandateTypeSelect'
import {
  generateObjectLabel,
  generateEstateLabel,
  generateRepresentativesAddress,
  generateRepresentativesNames,
} from '../utils/generateRegisterNumber'
import AutoSave from '@/common/atoms/Autosave'
import { useToast } from '@/components/ui/use-toast'

type MandateInfoFormProps = {
  mandate?: MandateFragment | null
  estate: Pick<
    EstateForMandatePageFragment,
    | 'id'
    | 'type'
    | 'address'
    | 'zipCode'
    | 'city'
    | 'seller'
    | 'estate_sellers'
    | 'mandate'
  >
}

export const MandateInfoForm = ({ mandate, estate }: MandateInfoFormProps) => {
  const { t } = useTranslation()
  const isAgencyMember = useIsAgencyMember()
  const { toast } = useToast()

  // If true, the mandate number has been generated and the mandate is now readonly (except for the mandate media and the signature date)
  const registryMandate = mandate?.mandate_registry
  const hasRegistryMandate = registryMandate != null

  const { schema } = useMandateInfoSchema()

  const [createMandate] = useCreateMandateMutation({
    refetchQueries: [GetEstateForMandatePageDocument, GetMandateDocument], // TODO get new data from response instead of refreshing
  })

  const [updateMandate] = useUpdateMandateMutation({
    refetchQueries: [GetMandateDocument], // TODO get new data from response instead of refreshing
  })

  const onCompleted = () => {
    toast({
      description: t('MandateInfoForm.toast.success'),
      variant: 'success',
      duration: 1000,
    })
  }

  const onError = (error: Error) => {
    console.error(error)
    toast({
      description: t('MandateInfoForm.toast.error'),
      variant: 'destructive',
      duration: 2000,
    })
  }

  const availableSellers = useMemo(() => {
    const otherSellers = estate.estate_sellers.map((es) => es.seller)
    otherSellers.unshift(estate.seller)
    return otherSellers
  }, [estate.seller, estate.estate_sellers])

  const representativesNames = useMemo(() => {
    return hasRegistryMandate
      ? registryMandate.representativesNames
      : generateRepresentativesNames(availableSellers)
  }, [availableSellers, registryMandate, hasRegistryMandate])

  const representativesAddress = useMemo(() => {
    return hasRegistryMandate
      ? registryMandate.representativesAddress
      : generateRepresentativesAddress(availableSellers)
  }, [availableSellers, registryMandate, hasRegistryMandate])

  const mandateObjectLabel = useMemo(() => {
    return hasRegistryMandate
      ? registryMandate.objectLabel
      : generateObjectLabel(mandate)
  }, [registryMandate, mandate, hasRegistryMandate])

  const estateLabel = useMemo(() => {
    return hasRegistryMandate
      ? registryMandate.estateLabel
      : generateEstateLabel(estate)
  }, [estate, registryMandate, hasRegistryMandate])

  const defaultValues = useMemo(() => {
    return {
      type: mandate?.type || undefined,
      orderNumber: registryMandate?.orderNumber || undefined,
      representativesNames:
        representativesNames == null ? '' : representativesNames,
      representativesAddress:
        representativesAddress == null ? '' : representativesAddress,
      objectLabel: mandateObjectLabel == null ? '' : mandateObjectLabel,
      estateLabel: estateLabel == null ? '' : estateLabel,
      amendmentNumber:
        mandate?.amendmentNumber == null ? '' : mandate?.amendmentNumber,
      precontractualDate: mandate?.precontractualDate
        ? new Date(mandate?.precontractualDate)
        : undefined,
      mainSignDate: mandate?.mainSignDate
        ? new Date(mandate?.mainSignDate)
        : undefined,
      reservePrice: mandate?.reservePrice || undefined,
      firstPrice: mandate?.firstPrice || '',
      effectiveDate: mandate?.effectiveDate
        ? new Date(mandate?.effectiveDate)
        : undefined,
      initialDuration:
        mandate?.initialDuration == null ? 3 : mandate?.initialDuration,
      renewalDuration:
        mandate?.renewalDuration == null ? 12 : mandate?.renewalDuration,
      maxRenewal: mandate?.maxRenewal == null ? 1 : mandate?.maxRenewal,
      fixedFee: mandate?.fixedFee == null ? 9000 : mandate?.fixedFee,
      fixedFeeThreshold:
        mandate?.fixedFeeThreshold == null
          ? 300000
          : mandate?.fixedFeeThreshold,
      proportionalFeesRate:
        mandate?.proportionalFeesRate == null
          ? 3.5
          : mandate?.proportionalFeesRate,
      feeVATRate: mandate?.feeVATRate == null ? 20 : mandate?.feeVATRate,
      feePerson:
        mandate?.feePerson == null
          ? Mandate_Fee_Person_Enum.Seller
          : mandate?.feePerson,
      particularConditions: mandate?.particularConditions || '',
      earnedMoney: mandate?.earnedMoney == null ? 10 : mandate?.earnedMoney,
      earnedMoneyPaid:
        mandate?.earnedMoneyPaid == null ? 5 : mandate?.earnedMoneyPaid,
      cancellationDate: mandate?.cancellationDate
        ? new Date(mandate?.cancellationDate)
        : undefined,
      effectiveCancellationDate: mandate?.effectiveCancellationDate
        ? new Date(mandate?.effectiveCancellationDate)
        : undefined,
    }
  }, [
    mandate,
    registryMandate,
    mandateObjectLabel,
    estateLabel,
    representativesNames,
    representativesAddress,
  ])

  const form = useForm<MandateInfoFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })
  const { control } = form

  // The date can be updated via MandateStatusCard, and we want to refresh our form
  // I dont watch the rest for performance reasons and to avoid re-rendering after an update
  useEffect(() => {
    form.reset({
      ...form.getValues(),
      mainSignDate: mandate?.mainSignDate
        ? new Date(mandate?.mainSignDate)
        : undefined,
      orderNumber: mandate?.mandate_registry?.orderNumber,
    })
  }, [mandate?.mainSignDate, mandate?.mandate_registry, form])

  const onSubmit = form.handleSubmit(async (values) => {
    if (estate?.id == null) return null

    // We omit some form properties which are not mapped to the database (if not Nhost will throw)
    const mandateValues = {
      ...omit(
        values,
        'estateLabel',
        'objectLabel',
        'representativesNames',
        'representativesAddress',
        'orderNumber'
      ),
      mainSignDate: values.mainSignDate?.toISOString(),
      precontractualDate:
        values.precontractualDate == null
          ? null
          : values.precontractualDate?.toISOString(),
      effectiveDate:
        values.effectiveDate == null
          ? null
          : values.effectiveDate?.toISOString(),
      cancellationDate:
        values.cancellationDate == null
          ? null
          : values.cancellationDate?.toISOString(),
      effectiveCancellationDate:
        values.effectiveCancellationDate == null
          ? null
          : values.effectiveCancellationDate?.toISOString(),
    }

    if (mandate?.id) {
      return updateMandate({
        variables: {
          id: mandate.id,
          values: {
            ...mandateValues,
          },
        },
        onCompleted,
        onError,
      })
    } else {
      return createMandate({
        variables: {
          values: {
            ...mandateValues,
            estateId: estate.id,
            mandate_registry: null,
          },
        },
        onCompleted,
        onError,
      })
    }
  })

  return (
    <FormProvider {...form}>
      <AutoSave delay={1500} onSubmit={onSubmit} />
      <Card>
        <CardContent className="tw-space-y-6 tw-pt-6">
          <FormField
            control={control}
            name="type"
            render={({ field }) => (
              <MandateTypeSelect
                field={field}
                disabled={!isAgencyMember || hasRegistryMandate}
              />
            )}
          />
          <div className="tw-flex tw-space-x-4">
            <FormField
              control={control}
              name="orderNumber"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.orderNumber')}
                  </FormLabel>
                  <FormControl>
                    <Input {...field} disabled />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="tw-w-1/2 tw-space-y-2">
              <Label htmlFor="generatedAt">
                {t('MandateInfoForm.field.generatedAt')}
              </Label>
              <Input
                id="generatedAt"
                disabled
                value={
                  registryMandate?.generatedAt
                    ? format(
                        new Date(registryMandate.generatedAt),
                        'dd MMMM yyyy HH:mm',
                        { locale: fr }
                      )
                    : ''
                }
              />
            </div>
          </div>

          <div className="tw-flex tw-space-x-4">
            <FormField
              control={control}
              name="representativesNames"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.representativesNames')}
                  </FormLabel>
                  <FormControl>
                    <Textarea {...field} disabled />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="representativesAddress"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.representativesAddress')}
                  </FormLabel>
                  <FormControl>
                    <Textarea {...field} disabled />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="tw-flex tw-space-x-4 tw-w-full">
            <FormField
              control={control}
              name="objectLabel"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.objectLabel')}
                  </FormLabel>
                  <FormControl>
                    <Input {...field} disabled />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="estateLabel"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.estateLabel')}
                  </FormLabel>
                  <FormControl>
                    <Textarea {...field} disabled />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          {/* <FormField
            control={control}
            name="amendmentNumber"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('MandateInfoForm.field.amendmentNumber')}
                </FormLabel>
                <FormControl>
                  <Input {...field} disabled={!isAgencyMember || hasOrderNumber} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          /> */}
          <div className="tw-flex tw-space-x-4">
            <FormField
              control={control}
              name="precontractualDate"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.precontractualDate')}
                  </FormLabel>
                  <FormControl>
                    <InputDatePicker
                      {...field}
                      fromYear={1900}
                      toYear={2100}
                      disabled={!isAgencyMember || hasRegistryMandate}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="mainSignDate"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.mainSignDate')}
                  </FormLabel>
                  <FormControl>
                    <InputDatePicker
                      {...field}
                      fromYear={1900}
                      toYear={2100}
                      // This field is readonly, the date is set while uploading the mandate
                      disabled={true}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="tw-flex tw-space-x-4">
            <FormField
              control={control}
              name="reservePrice"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.reservePrice')}
                  </FormLabel>
                  <FormControl>
                    <InputPrice
                      value={field.value}
                      onValueChange={(value) => field.onChange(value)}
                      disabled={!isAgencyMember || hasRegistryMandate}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="firstPrice"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>{t('MandateInfoForm.field.firstPrice')}</FormLabel>
                  <FormControl>
                    <InputPrice
                      value={field.value}
                      onValueChange={(value) => field.onChange(value)}
                      disabled={!isAgencyMember || hasRegistryMandate}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="tw-flex tw-space-x-4">
            <FormField
              control={control}
              name="effectiveDate"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.effectiveDate')}
                  </FormLabel>
                  <FormControl>
                    <InputDatePicker
                      {...field}
                      fromYear={1900}
                      toYear={2100}
                      disabled={!isAgencyMember || hasRegistryMandate}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="initialDuration"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.initialDuration')}
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      disabled={!isAgencyMember || hasRegistryMandate}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="tw-flex tw-space-x-4">
            <FormField
              control={control}
              name="renewalDuration"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.renewalDuration')}
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      disabled={!isAgencyMember || hasRegistryMandate}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="maxRenewal"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>{t('MandateInfoForm.field.maxRenewal')}</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      disabled={!isAgencyMember || hasRegistryMandate}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="tw-flex tw-space-x-4">
            <FormField
              control={control}
              name="fixedFee"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>{t('MandateInfoForm.field.fixedFee')}</FormLabel>
                  <FormControl>
                    <InputPrice
                      value={field.value}
                      onValueChange={(value) => field.onChange(value)}
                      disabled={!isAgencyMember || hasRegistryMandate}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="fixedFeeThreshold"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.fixedFeeThreshold')}
                  </FormLabel>
                  <FormControl>
                    <InputPrice
                      value={field.value}
                      onValueChange={(value) => field.onChange(value)}
                      disabled={!isAgencyMember || hasRegistryMandate}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="tw-flex tw-space-x-4">
            <FormField
              control={control}
              name="proportionalFeesRate"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.proportionalFeesRate')}
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      disabled={!isAgencyMember || hasRegistryMandate}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="feeVATRate"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>{t('MandateInfoForm.field.feeVATRate')}</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      disabled={!isAgencyMember || hasRegistryMandate}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <FormField
            control={control}
            name="earnedMoney"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('MandateInfoForm.field.earnedMoney')}</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    disabled={!isAgencyMember || hasRegistryMandate}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={control}
            name="earnedMoneyPaid"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('MandateInfoForm.field.earnedMoneyPaid')}
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    disabled={!isAgencyMember || hasRegistryMandate}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="feePerson"
            render={({ field }) => (
              <MandateFeePersonSelect
                field={field}
                disabled={!isAgencyMember || hasRegistryMandate}
              />
            )}
          />
          <FormField
            control={control}
            name="particularConditions"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('MandateInfoForm.field.particularConditions')}
                </FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    disabled={!isAgencyMember || hasRegistryMandate}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="tw-flex tw-space-x-4">
            <FormField
              control={control}
              name="cancellationDate"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.cancellationDate')}
                  </FormLabel>
                  <FormControl>
                    <InputDatePicker
                      {...field}
                      fromYear={1900}
                      toYear={2100}
                      disabled={!isAgencyMember}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="effectiveCancellationDate"
              render={({ field }) => (
                <FormItem className="tw-w-1/2">
                  <FormLabel>
                    {t('MandateInfoForm.field.effectiveCancellationDate')}
                  </FormLabel>
                  <FormControl>
                    <InputDatePicker
                      {...field}
                      fromYear={1900}
                      toYear={2100}
                      disabled={!isAgencyMember}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </CardContent>
      </Card>
    </FormProvider>
  )
}
